import Openings from "../Interfaces/Openings";
import careerImage from '../Assets/images/careerImage.png';

export const openings : Openings[] = [
    {
        position : "React Native Developer",
        location : "Gurugram, Haryana",
        type : "Full Time",
        experience : "3+ Years",
        image : careerImage,
        // responsibilities : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type",
        responsibilitiesKey : ["Listen to what they say about you", "Randomised words which.", " Internet tend to repeat predefined chunks", "Automate multiple scenarios "],
        // responsibilitiesNotes : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard",
        requirements : "React Native developers interested in building performant mobile apps on both the iOS and Android platforms. You will be responsible for architecting and building these applications, as well as coordinating with the teams responsible for other layers of the product infrastructure. Building a product is a highly collaborative effort, and as such, a strong team player with a commitment to perfection is required.",
        requirementsKey : ["3 Years of experience in React Native", "Graduate Degree in Similar Field"]
    },
    {
        position : "Ruby on Rails Developer ",
        location : "Gurugram, Haryana",
        type : "Full Time",
        experience : "3+ Years",
        image : careerImage,
        // responsibilities : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type",
        responsibilitiesKey : ["Listen to what they say about you", "Randomised words which.", " Internet tend to repeat predefined chunks", "Automate multiple scenarios "],
        // responsibilitiesNotes : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard",
        requirements : "Write clean, maintainable and efficient code  Design robust, scalable and secure features Contribute in all phases of the development lifecycle Follow best practices (test-driven development, continuous integration, SCRUM, refactoring and code standards) Drive continuous adoption and integration of relevant new technologies into design. Proven work experience in software development .Demonstrable knowledge of front-end technologies such as JavaScript, HTML, CSS and JQuery Experience developing highly interactive applications.",
        requirementsKey : ["3 Years of experience in React Native", "Graduate Degree in Similar Field"]
    },
    // {
    //     position : "IoT Developer",
    //     location : "Remote",
    //     type : "Full Time",
    //     experience : "3+ Years",
    //     image : careerImage,
    //     // responsibilities : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type",
    //     responsibilitiesKey : ["Listen to what they say about you", "Randomised words which.", " Internet tend to repeat predefined chunks", "Automate multiple scenarios "],
    //     // responsibilitiesNotes : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard",
    //     requirements : "When an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five cedfgfnturies, but also the leap into electronic typesetting.",
    //     requirementsKey : ["2 Years of experience in React Native", "Graduate Degree in Similar Field"]
    // },
    {
        position : "Node Developer",
        location : "Gurugram, Haryana",
        type : "Part Time",
        experience : "3+ Years",
        image : careerImage,
        // responsibilities : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type",
        responsibilitiesKey : ["Listen to what they say about you", "Randomised words which.", " Internet tend to repeat predefined chunks", "Automate multiple scenarios "],
        // responsibilitiesNotes : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard",
        requirements : " Node.js developer to work on Backend Api . You will be collaborating with our front-end application developers, designing back-end components, and integrating data storage and protection solutions. To ensure success as a Node.js developer, you should possess extensive knowledge of Node.Js based services and experience in a similar role. An accomplished Node.js developer will be someone whose expertise results in the stable, fast, and secure exchange of data between servers and end-users",
        requirementsKey : ["2 Years of experience in React Native", "Graduate Degree in Similar Field"]
    },
    {
        position : "React JS Developer",
        location : "Remote",
        type : "Full Time",
        image : careerImage,
        experience : "4+ Years",
        // responsibilities : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type",
        responsibilitiesKey : ["Listen to what they say about you", "Randomised words which.", " Internet tend to repeat predefined chunks", "Automate multiple scenarios "],
        // responsibilitiesNotes : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard",
        requirements : "Java Script developer who is proficient with React.js. Your primary focus will be on developing user interface components and implementing them following well-known React.js workflows (such as Flux or Redux). You will ensure that these components and the overall application are robust and easy to maintain. You will coordinate with the rest of the team working on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and quality product is important.",
        requirementsKey : ["2 Years of experience in React Native", "Graduate Degree in Similar Field"]
    }
]